<template>
  <v-container fluid class="px-8">
    <v-row align="center">
      <v-col>
        <h3>Domains</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions class="pa-5">
            <v-row class="custom--gutters">
              <v-col>
                <v-text-field
                    v-model.trim="table.search"
                    label="Search..."
                    solo
                    outlined
                    dense
                    flat
                    hide-details
                    class="font-size-15"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="auto">
                <v-btn block depressed small height="40" color="cyan accent-4 " class="custom--btn" dark @click="addDomain.show = true">Add My Domain</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="table.headers"
          :items="userDomains"
          :items-per-page="10"
          class="table--sm show-sort-icons-always elevation-0 custom--border fill-space-first-cell table--row-70"
          :search="table.search"
          loading-text="Loading... Please wait"
          :loading="table.loading"
          hide-default-footer
          :page.sync="table.page"
          @page-count="table.pageCount = $event"
        >
          <template v-slot:item.url="{ item }">
            <div style="min-width: 250px; max-width: 450px;" class="text-truncate">{{item.url}}</div>
          </template>

          <template v-slot:item.campaigns="{ item }">
            {{item.campaigns ? item.campaigns.toLocaleString() : 0}}
          </template>

          <template v-slot:item.isVerified="{ item }" >
            <div style="width: 150px" class="ma-auto" :key="'wrap' + item._id">
              <DomainRefresh
                  v-if="tooltipByDomain[item._id]"
                  @reloadClicked="verifyDomain(item._id)"
                  @tooltipHidden="tooltipByDomain[item._id].isErrorWhileVerify = false; tooltipByDomain[item._id].show = false"
                  @tooltipClicked="tooltipByDomain[item._id].show = false; instructionsModal.domain = item.url; instructionsModal.show = true"
                  :showTooltip="tooltipByDomain[item._id].show"
                  :isVerified="item.isVerified"
                  :isErrorWhileVerify="tooltipByDomain[item._id].isErrorWhileVerify"
                  :tooltipLink="`Your domain is not connected yet. If it’s been more than 48 hours make sure that you followed all of the <a href='#'>instructions</a> correctly.`"
              />
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn class="custom--btn" color="cyan accent-4" outlined depressed small @click="deleteDomain.domainToDelete = item; deleteDomain.showModal = true;">Delete</v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mt-3 mb-0" align="center" v-show="table.pageCount > 1">
      <v-col cols="12">
        <v-pagination
            v-model="table.page"
            :length="table.pageCount"
        ></v-pagination>
      </v-col>
    </v-row>

    <ConfirmModal
        title="Delete Domain"
        :show.sync="deleteDomain.showModal"
        @onConfirm="onDeleteDomain"
        @onClose="deleteDomain.showModal = false; deleteDomain.domainToDelete = null;"
    >
      <v-card-text class="pa-0" v-if="deleteDomain.domainToDelete">
        <p class="mb-0">
          <span class="text--primary font-weight-medium">URL:</span> {{deleteDomain.domainToDelete.url}}
        </p>
      </v-card-text>
    </ConfirmModal>

    <v-dialog
        v-model="addDomain.show"
        width="778"
        @keydown="handleEnterPressed"
    >
      <v-card class="">
        <v-card-title class="text-h6 px-7 py-6 grey lighten-4 ">
          <div class="position-relative width-100 ">
            <span class="d-block pr-6">Add New Domain</span>
            <v-icon @click="addDomain.show = false" small class="cursor-pointer absolute-right absolute-vertical-center position-absolute">mdi-close</v-icon>
          </div>
        </v-card-title>

        <v-form class="pa-7" v-model="addDomain.valid" ref="createDomainForm" id="createDomainForm" @submit.prevent="addNewDomain" >
          <v-expansion-panels flat accordion v-model="addDomain.currentStep">
            <v-expansion-panel :class="{'domains-panel--opacity': addDomain.currentStep > 0}" class="rounded-4 custom--border domains-expansion-panel"
            >
              <v-expansion-panel-header :hide-actions="true" :class="{'pointer-events-none':  addDomain.currentStep === 0}">
                <v-row align="center">
                  <v-col cols="12" sm="auto" class="domains-panel--opacity--target">
                    <span class="font-weight-medium font-size-18 ">Step 1</span>
                  </v-col>
                  <v-col class="pt-sm-3 pt-0 domains-panel--opacity--target" >
                    <span class="text--secondary ">Enter your domain</span>
                  </v-col>
                  <v-col cols="auto" class="ml-auto" v-if="addDomain.currentStep > 0">
                    <v-btn color="cyan accent-4" text small depressed>Edit</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mx-n6" style="max-width: none"></v-divider>
                <div class="pt-6 pb-2">
                  <div>
                    <p class="text--disabled">Enter the domain that you bought from your domain provider. Example: website.com</p>
                  </div>
                  <v-text-field
                      v-model.trim="addDomain.url"
                      persistent-placeholder
                      placeholder="Enter your domain"
                      outlined
                      dense
                      solo
                      flat
                      :rules="requiredRules"
                      hide-details="auto"
                      class="font-size-15"
                      id="addDomainUrlInput"
                      :error-messages="addDomain.errors.url"
                      @input="addDomain.errors.url = ''"
                  ></v-text-field>

                  <v-btn
                      @click="toNextStep"
                      color="cyan accent-4 ma-auto" class="mt-4 primary--btn custom--btn" :disabled="!addDomain.url || addDomain.errors.url.length > 0" dark depressed block height="40"
                  >
                    Next Step
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :disabled="addDomain.currentStep < 1 || !addDomain.currentStep" :class="{'domains-panel--opacity': addDomain.currentStep !== 1}" class="domains-expansion-panel rounded-4 mt-4 custom--border" >
              <v-expansion-panel-header :hide-actions="true" :class="{'pointer-events-none':  addDomain.currentStep === 1}">
                <v-row align="center">
                  <v-col cols="12" sm="auto" class="domains-panel--opacity--target">
                    <span class="font-weight-medium font-size-18 ">Step 2</span>
                  </v-col>
                  <v-col class="pt-sm-3 pt-0 domains-panel--opacity--target">
                    <span class="text--secondary ">Create c CNAME record</span>
                  </v-col>
                  <v-col cols="auto" class="ml-auto" v-if="addDomain.currentStep > 1">
                    <v-btn color="cyan accent-4" text small depressed>View</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mx-n6" style="max-width: none"></v-divider>
                <div class="pt-6 pb-2">
                  <DomainInstructions
                    :domain="addDomain.url"
                    :providers="addDomain.providers"
                  />

                  <v-btn
                      @click="addDomain.currentStep++"
                      color="cyan accent-4 ma-auto" class="mt-6 primary--btn custom--btn" :disabled="!addDomain.url" dark depressed block height="40"
                  >
                    Next Step
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :disabled="addDomain.currentStep < 2 || !addDomain.currentStep" :class="{'domains-panel--opacity': addDomain.currentStep !== 2}" class="domains-expansion-panel rounded-4 mt-4 custom--border" >
              <v-expansion-panel-header :hide-actions="true" :class="{'pointer-events-none':  addDomain.currentStep === 2}">
                <v-row align="center">
                  <v-col cols="12" sm="auto" class="domains-panel--opacity--target">
                    <span class="font-weight-medium font-size-18 ">Step 3</span>
                  </v-col>
                  <v-col class="pt-sm-3 pt-0 domains-panel--opacity--target">
                    <span class="text--secondary ">Further instructions</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mx-n6" style="max-width: none"></v-divider>
                <div class="pt-6 pb-2">
                  <div>
                    <p class="text--disabled">
                      This domain will appear on the list of your domains and within the <span class="text--secondary font-weight-medium">next 2 days</span>. We recommend to come back to this page and press refresh to check if it’s connected.
                    </p>
                  </div>

                  <div class="">
                    <v-card outlined class="custom--border">
                      <v-img
                          max-width="100%"
                          width="100%"
                          src="../assets/images/domains-verify.jpg"
                      ></v-img>
                    </v-card>

                    <DomainRefresh
                        v-if="false"
                        @reloadClicked="verifyDomain(addDomain.url, true)"
                        @tooltipHidden="addDomain.isErrorWhileVerify = false; addDomain.showTooltip = false"
                        :showTooltip="addDomain.showTooltip"
                        :isVerified="addDomain.isVerified"
                        :isErrorWhileVerify="addDomain.isErrorWhileVerify"
                        :tooltipLink="`Connect <a href='#' target='_blank'>link</a>`"
                    />
                  </div>

                  <v-btn
                      type="submit"
                      color="cyan accent-4 ma-auto" class="mt-6 primary--btn custom--btn" :disabled="!addDomain.url" dark depressed block height="40"
                  >
                    Save Domain
                  </v-btn>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="instructionsModal.show"
        width="778"
    >
      <v-card class="">
        <v-card-title class="text-h6 px-7 py-6 grey lighten-4 ">
          <div class="position-relative width-100 ">
            <span class="d-block pr-6">Domain Instructions</span>
            <v-icon @click="instructionsModal.show = false" small class="cursor-pointer absolute-right absolute-vertical-center position-absolute">mdi-close</v-icon>
          </div>
        </v-card-title>

        <div class="pa-7">
          <DomainInstructions
              :domain="instructionsModal.domain"
              :providers="addDomain.providers"
          />
        </div>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import { mapGetters, mapState, mapMutations } from 'vuex';
import domainsApi from '../api/domains';
import {copyTextToClipboard, showNotification} from "../js/helpers";
import ConfirmModal from '../components/ConfirmModal';
import DomainRefresh from '../components/DomainRefresh';
import DomainInstructions from '../components/DomainInstructions';
import {requiredRules} from "../js/validators";
import {validationMessages} from "../helpers/contants";

import $ from 'jquery';

const _providers = [
  { url: 'https://www.namecheap.com/support/knowledgebase/article.aspx/9646/2237/how-to-create-a-cname-record-for-your-domain', name: 'Namecheap' },
  { url: 'https://godaddy.com/help/add-a-cname-record-19236', name: 'GoDaddy' },
  { url: 'https://community.cloudflare.com/t/how-do-i-add-a-cname-record/59', name: 'Cloudflare' },
  { url: 'https://support.google.com/domains/answer/3290350?hl=en-GB&visit_id=637747254500350377-2950872597&rd=1', name: 'Google Domains' },
  { url: 'https://help.shopify.com/en/manual/domains/managing-domains/edit-dns-settings', name: 'Shopify' },
];

export default {
  name: 'Domains',

  components: {
    ConfirmModal, DomainRefresh, DomainInstructions
  },

  watch: {
    'addDomain.show': {
      handler: function() {
        this.clearAddDomain();
      }
    },
    'addDomain.currentStep': {
      handler: function() {
        this.$nextTick(function() {
          const form = document.querySelector('#createDomainForm');
          if (form) {
            const modal = form.closest('.v-dialog__content.v-dialog__content--active');

            if (modal) {
              modal.focus();
            }
          }
        });
      }
    }
  },

  computed: {
    ...mapGetters('domains', [
      'userDomains'
    ])
  },

  methods: {
    ...mapMutations(['setLoading']),
    toNextStep() {
      if (!this.validateDomainUrl()) { return }

      this.addDomain.currentStep++;
    },
    clearAddDomain() {
      this.addDomain.url = '';
      this.addDomain.valid = false;
      this.addDomain.isVerified = false;
      this.addDomain.isErrorWhileVerify = false;
      this.addDomain.showTooltip = false;
      this.addDomain.currentStep = 0;

      this.clearAddDomainErrors();

      if (this.$refs.createDomainForm) {
        this.$refs.createDomainForm.resetValidation();
      }
    },
    async verifyDomain(domainId, fromModal = false) {
      this.table.loading = true;

      const res = await domainsApi.verifyDomain(domainId);
      this.table.loading = false;

      if (!res || !res.status || !res.isVerified) {
        if (fromModal) {
          this.addDomain.showTooltip = true;
          this.addDomain.isErrorWhileVerify = true;
        } else {
          this.tooltipByDomain[domainId].show = true;
          this.tooltipByDomain[domainId].isErrorWhileVerify = true;
        }

        return;
      }

      showNotification({ title: 'Verified!' });
    },
    checkAddDomainValidity() {
      return Object.values(this.addDomain.errors).some(_value => _value.length > 0);
    },
    handleEnterPressed(e) {
      const charCode = e.which || e.keyCode;

      if (charCode === 13) {
        e.preventDefault();

        if (!this.addDomain.url || this.checkAddDomainValidity()) { return }

        if (this.addDomain.currentStep < 2) {
          this.toNextStep();
        } else if (this.addDomain.currentStep === 2) {
          const form = document.getElementById('createDomainForm');

          if (form) {
            form.requestSubmit();
          }
        }
      }
    },
    clearAddDomainErrors() {
      Object.keys(this.addDomain.errors).forEach(_errorKey => this.addDomain.errors[_errorKey] = '');
    },
    goToFirstStep(errorMessage) {
      if (!errorMessage) { return }

      this.addDomain.currentStep = 0;
      this.addDomain.errors.url = errorMessage;

      this.$nextTick(function() {
        const input = document.getElementById('addDomainUrlInput');
        if (input) {
          setTimeout(() => input.focus(), 350);
        }
      });
    },
    validateDomainUrl() {
      const { url} = this.addDomain;

      const isDomainAdded = this.userDomains.findIndex(_domain => _domain.url === url) !== -1;
      if (isDomainAdded) {
        this.goToFirstStep('Domain already exists');
        return false;
      }

      return true;
    },
    async addNewDomain() {
      this.clearAddDomainErrors();

      const { url } = this.addDomain;
      if (!url) { return showNotification({ type: 'error', title: 'Domain is required.' }) }

      if (!this.validateDomainUrl()) { return }

      this.setLoading(true);
      const { status, domain, message = validationMessages['api'] } = await domainsApi.addDomain({
        url: url.replace(/^https?\:\/\//i, '')
      });

      this.setLoading(false);

      if (!status || typeof domain !== 'object' || !domain._id) {
        this.goToFirstStep(message);
        return;
      }

      this.$set(this.tooltipByDomain, domain._id, {});
      this.$set(this.tooltipByDomain[domain._id], 'show', false);
      this.$set(this.tooltipByDomain[domain._id], 'isErrorWhileVerify', false);
      this.clearAddDomainErrors();

      this.addDomain.show = false;
      showNotification({ title: 'Domain created!' });
    },
    async onDeleteDomain() {
      this.deleteDomain.showModal = false;

      const { domainToDelete } = this.deleteDomain;
      if (!domainToDelete) { return }

      this.table.loading = true;

      const { status } = await domainsApi.deleteDomain(domainToDelete._id);
      this.table.loading = false;

      if (!status) { return }

      showNotification({ title: 'Deleted!' });
    }
  },

  beforeMount() {
    this.requiredRules = requiredRules;
    this.copyTextToClipboard = copyTextToClipboard;
  },

  async mounted() {
    this.setLoading(true);

    await domainsApi.getDomains();

    this.userDomains.forEach(_domain => {
      this.$set(this.tooltipByDomain, _domain._id, {});
      this.$set(this.tooltipByDomain[_domain._id], 'show', false);
      this.$set(this.tooltipByDomain[_domain._id], 'isErrorWhileVerify', false);
    });

    this.$nextTick(function() {
      this.setLoading(false);
    });
  },

  data: () => ({
    instructionsModal: { show: false, domain: '' },
    addDomain: {
      show: false, valid: false, url: '', isVerified: false, currentStep: 0,
      isErrorWhileVerify: false, showTooltip: false,
      providers: _providers, errors: { url: '' }
    },
    tooltipByDomain: {},
    deleteDomain: { showModal: false, domainToDelete: null },
    url: '',
    table: {
      headers: [
        { text: 'Domain', value: 'url', divider: true },
        { text: 'Campaigns', value: 'campaignsCount', align: 'center', divider: true, width: 260 },
        { text: 'Status', value: 'isVerified', align: 'center', divider: true, width: 320 },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: 220, divider: true }
      ],
      search: '',
      loading: false,
      page: 1,
      pageCount: 0
    }
  }),
}
</script>

<style lang="scss">
.domains-panel--opacity .domains-panel--opacity--target {
  opacity: .5 !important;
}
.v-expansion-panel--active.domains-expansion-panel > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  transform: rotate(90deg);
}
</style>