<template>
  <div>
    <p class="text--disabled mb-1">Go to your domain provider select <a :href="domain" target="_blank">{{domain}}</a> and create a CNAME record that points <b>*</b> subdomain to the domain from the input below ({{alias}}).</p>
    <v-row align="center" justify="center" dense class="flex-nowrap mt-4">
      <v-col>
        <v-text-field
            :value="alias"
            solo
            outlined
            dense
            flat
            readonly
            class="color--inherit grey--text text--darken-1 font-size-15"
            hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn class="custom--btn" color="cyan accent-4" outlined depressed height="40" @click="copyTextToClipboard(alias)">Copy</v-btn>
      </v-col>
    </v-row>

    <div class="mt-6 font-size-13">
      <v-divider class="mb-4"></v-divider>
      <span class="text--disabled">
         Here are instructions for the most popular domain providers. If you don’t see your provider on the list and you still need help -
      <a href="mailto:keytools.assist@gmail.com">write to us</a>
      </span>

      <div class="mt-3">
        <a
            v-for="provider in providers"
            :key="provider.url"
            :href="provider.url" target="_blank"
            class="mr-4"
        >
          {{provider.name}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {copyTextToClipboard} from '../js/helpers';
import {mapGetters} from "vuex";

export default {
  name: 'DomainInstructions',

  computed: {
    ...mapGetters('domains', ['defaultSystemDomain']),
    alias() {
      return `connect.${this.defaultSystemDomain.url}`;
    }
  },

  props: ['domain', 'providers'],

  watch: {

  },

  created() {

  },

  beforeMount() {
    this.copyTextToClipboard = copyTextToClipboard;
  },

  mounted() {

  },

  methods: {
  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>
