<template>
  <v-popover class="fill-height d-flex justify-center align-center popover--width" placement="top" popoverBaseClass="v-tooltip__content tooltip popover" container="#app" trigger="hover" :open="showTooltip" @apply-hide="handleHiddenTooltip">
    <v-row dense align="center" justify="center" class="flex-nowrap">
      <template v-if="isVerified">
        <v-col>
          <v-alert
              dense
              dark
              height="28"
              class="alert-align-vertical-center mb-0 green lighten-2 font-size-12"
          >
            {{ connectedText || 'Connected' }}
            <v-icon class="ml-4" style="margin-bottom: 3px" right x-small>mdi-check</v-icon>
          </v-alert>
        </v-col>
      </template>
      <template v-else>
        <v-col>
          <v-alert
              dense
              dark
              height="28"
              class="alert-align-vertical-center mb-0 blue-grey darken-1 font-size-12  px-3 text-no-wrap"
          >
            {{ notConnectedText || 'Not connected' }}
          </v-alert>
        </v-col>

        <v-col cols="auto">
          <v-btn height="28" class="rounded-4" x-small fab depressed dark color="blue-grey darken-1" @click="handleReloadClick" >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
      </template>
    </v-row>

    <template slot="popover">
      <span class="cursor-pointer" v-if="isErrorWhileVerify && tooltipLink" v-html="tooltipLink" @click.prevent="handleTooltipClicked"></span>
      <span v-else>{{isVerified ? connectedText ? connectedText : 'Connected' : 'Click to verify'}}</span>
    </template>
  </v-popover>
</template>

<script>


export default {
  name: 'DomainRefresh',

  computed: {

  },

  props: [
    'notConnectedText', 'connectedText', 'showTooltip', 'isVerified', 'isErrorWhileVerify', 'tooltipLink'
  ],

  watch: {

  },

  created() {

  },

  mounted() {

  },

  methods: {
    handleHiddenTooltip() {
      this.$emit('tooltipHidden');
    },
    handleReloadClick() {
      this.$emit('reloadClicked');
    },
    handleTooltipClicked() {
      this.$emit('tooltipClicked');
    }
  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>
